<template>
  <vue-editor
    id="editor"
    useCustomImageHandler
    @image-added="handleImageAdded"
    v-model="noticeInfo"
    @text-change="updateChange"
    :disabled="mode == 'look' ? true : false"
  >
  </vue-editor>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  props: {
    mode: {
      type: String,
      default: "add",
    },
  },
  components: {
    VueEditor,
  },
  data() {
    return {
      noticeInfo: "",
    };
  },
  methods: {
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      const formData = new FormData(); //html自带的表单数据类
      formData.append("file", file); //file是文件对象，在服务端接收的字段名也是"file"
      const { flag, data } = await this.$post(
        "/xng/upload/uploadFile",
        formData
      );
      if (flag) {
        //cursorLocation是光标位置，res.data.url是上传后的url地址
        Editor.insertEmbed(cursorLocation, "image", data);
        resetUploader(); //重置文件上传器
        this.$emit("update", this.noticeInfo);
      }
    },
    updateChange(delta, oldDelta, source) {
      this.$emit("update", this.noticeInfo);
    },
  },
};
</script>

<style>
#editor {
  height: 500px;
}
</style>
